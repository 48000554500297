<template>
  <b-modal
    v-model="isOpen"
    size="lg"
    :title="'Identificador: ' + model.name"
    ok-only
    hide-header-close
    ok-title="Fechar"
    @ok="close"
  >
    <b-card>

      <b-row>
        <b-col>
          Para conectar o seu WhatsApp é preciso seguir os passos abaixo:
          <br /><br />
          <ol>
            <li>Abra o WhatsApp</li>
            <li>No canto superior direito, clique nos <b>3 pontinhos</b> e então em <b>Dispositivos conectados</b></li>
            <li>Agora clique no botão <b>Conectar dispositivo</b></li>
            <li>A camera irá abrir, aponte ela para o <b>QRCode</b> ao lado e a conexão será realizada.</li>
          </ol>
          <br>
          <b>Atenção:</b> Caso a conexão não seja realizada, é preciso excluir esta configuração e então criar uma nova.
        </b-col>
        <b-col class="text-center">
          <b-img :src="model.data.qrcode.base64" alt="QRCode" />
        </b-col>
      </b-row>

    </b-card>
  </b-modal>
</template>

<script>
export default {
  name: 'WhatsAppSettingsQRCodeModal',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    currentSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      model: {
        name: '',
        data: {
          qrcode: {
            base64: '',
          },
        },
      },
    }
  },
  watch: {
    open(value) {
      this.isOpen = value
    },
    currentSettings(value) {
      if (!value.data) {
        return
      }
      this.model = value
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
