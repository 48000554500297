<template>
  <div>
    <b-row>
      <b-col>
        <i2-form-input
          v-model="model.name"
          name="name"
          label="Nome"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <i2-form-input
          v-model="model.phone_number"
          name="phones"
          label="Número do Telefone (Ex: 5551988884444)"
          placeholder="5551988884444"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12"
        md="12"
        class="text-right"
      >
        <!-- Form Actions -->
        <b-button
          variant="primary"
          type="submit"
          class="mr-2"
          @click="createInstance"
        >

          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          Salvar
        </b-button>
        <b-button
          variant="outline-danger"
          type="button"
          @click="onCancel"
        >

          <font-awesome-icon
            prefix="far"
            :icon="['far', 'times-circle']"
            class="mr-50"
          />
          Cancelar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'WhatsAppSettingsForm',
  props: {
  },
  data() {
    return {
      isOpen: false,
      model: {
        uuid: '',
        name: '',
        phone_number: '',
      },
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.isOpen = true
      }
    },
  },
  methods: {
    async createInstance() {
      const response = await this.$http.post('/whatsapp/create', this.model)

      if (response.data) {
        this.resetModel()
        this.$notify.success('Configuração realizada com sucesso.')
        this.$emit('onCreate')
        return
      }
      this.$notify.error('Não foi possível realizar a configuração.')
    },
    onCancel() {
      this.$emit('onCancel')
    },
    resetModel() {
      this.model = {
        uuid: '',
        name: '',
        phone_number: '',
      }
    },
  },
}
</script>

<style scoped>

</style>
