<template>
  <div>
    <page-header
      name="Configurações do WhatsApp"
    >
      <b-button
        variant="primary"
        @click="newWhatsApp"
      >
        <FeatherIcon icon="PlusIcon" />
        &nbsp;<span class="text-nowrap">Adicionar</span>
      </b-button>
    </page-header>

    <b-card>
      <b-row class="text-right">
        <b-col class="text-right mb-1">
          <b-button variant="outline-primary" @click="getConfigurations">
            <feather-icon icon="RefreshCwIcon" />
            Atualizar
          </b-button>
        </b-col>
      </b-row>
      <Grid
        :fields="fields"
        :items="items"
        :show-search="false"
        edit-button-label="edit"
        new-button-label="new"
      >
        <template #cell(name)="data">
          {{ data.item.name }}<br>
          <b-badge :variant="getStatusVariant(data.item)">
            {{ getLabelVariant(data.item) }}
          </b-badge>
        </template>
        <template #cell(created_at)="data">
          {{ formatDate(data.item.created_at) }}
        </template>
        <template #cell(deleted_at)="data">
          {{ formatDate(data.item.deleted_at) }}
        </template>

        <template #cell(actions)="data">
          <b-button-toolbar style="display: block">
            <b-button-group :disabled="isDisbledActions(data.item)">
              <i2-icon-button :disabled="isDisbledActions(data.item)" variant="primary" icon="CodeIcon" @click="showQrCode(data.item)" />
              <i2-icon-button :disabled="isDisbledActions(data.item)" variant="outline-danger" icon="TrashIcon" @click="deleteSettings(data.item)" />
            </b-button-group>
          </b-button-toolbar>
        </template>
      </Grid>
    </b-card>

    <i2-side-bar window-title="Nova Configuração" :is-active.sync="isActivated">

      <whats-app-settings-form
        @onCancel="closeFormWhatsApp"
        @onCreate="settingsCreate"
      />
    </i2-side-bar>

    <whats-app-settings-q-r-code-modal :open="isOpenQrCodeModal" :current-settings="currentItem" @close="closeQrCodeModal" />

  </div>
</template>

<script>
import WhatsAppSettingsForm from '@/views/settings/WhatsAppSettingsForm.vue'
import PageHeader from '@/components/i2/layout/PageHeader.vue'
import moment from 'moment-timezone'

import { BButtonToolbar, BButtonGroup } from 'bootstrap-vue'
import WhatsAppSettingsQRCodeModal from '@/views/settings/WhatsAppSettingsQRCodeModal.vue'

export default {
  name: 'WhatsAppSettings',
  components: {
    WhatsAppSettingsQRCodeModal,
    PageHeader,
    WhatsAppSettingsForm,
    BButtonToolbar,
    BButtonGroup,
  },
  data() {
    return {
      isActivated: false,
      isOpenQrCodeModal: false,
      isOpenNotificationNumberModal: false,
      currentItem: {},
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-30',
        },
        {
          key: 'phone_number',
          label: 'Número do Telefone',
          class: 'w-20',
        },
        {
          key: 'created_at',
          label: 'Criado Em',
          class: 'w-15 text-center',
        },
        {
          key: 'deleted_at',
          label: 'Excluído Em',
          class: 'w-15 text-center',
        },
        {
          key: 'actions',
          label: 'Ações',
          class: 'w-10 text-center',
        },
      ],
    }
  },
  mounted() {
    this.getConfigurations()
  },
  methods: {
    newWhatsApp() {
      this.isActivated = true
    },
    closeFormWhatsApp() {
      this.isActivated = false
    },
    async getConfigurations() {
      this.items = await this.$http.get('whatsapp')
    },
    formatDate(date) {
      if (date === null) {
        return ''
      }
      return moment.utc(date).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss')
    },
    showQrCode(item) {
      this.isOpenQrCodeModal = true
      this.currentItem = item
    },
    async closeQrCodeModal() {
      this.isOpenQrCodeModal = false
      this.currentItem = {}
      await this.getConfigurations()
    },
    deleteSettings(item) {
      const message = 'Ao excluir a configuração, o sistema não irá mais enviar mensagens por WhatsApp. '
          + 'Caso haja um dispositivo conectado, o mesmo será desconectado.'
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Confirma a exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        }).then(async response => {
          if (response) {
            await this.$http.delete(`whatsapp/${item.uuid}`)
            this.$notify.success('Configuração excluída com sucesso.')
            await this.getConfigurations()
          }
        })
    },
    async settingsCreate() {
      await this.getConfigurations()
    },
    getStatusVariant(instance) {
      switch (instance.status) {
        case 'open':
          return 'success'
        case 'connecting':
          return 'primary'
        default:
          return 'danger'
      }
    },
    getLabelVariant(instance) {
      switch (instance.status) {
        case 'open':
          return 'Conectado'
        case 'connecting':
          return 'Aguardando Conexão'
        default:
          return 'Inutilizado'
      }
    },
    isDisbledActions(item) {
      if (item.deleted_at) {
        return true
      }
      switch (item.status) {
        case 'connecting':
        case 'open':
          return false
        default:
          return true
      }
    },
  },
}
</script>

<style scoped>

</style>
